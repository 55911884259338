.main_content{
	margin-top: 175px;
	@include mobile {
		margin-top: 210px;
	}
	@include tablet {
		margin-top: 220px;
	}
}
.block_section{
	@extend %fullBlock;
}
a{
	color: inherit;
	text-decoration: none;
}
.leftSide{
	float: left;
	width: calc(100% - 300px);
	padding-right: 30px;
}
.owl-carousel{
	display: block;
}
.banner_item{
	@extend %fullBlock;
	background-color: #f9f9f9;
	padding: 30px 20px;
	&__top{
		@extend %fullBlock;
		&__logo{
			width: 100px;
			height: 100px;
			border-radius: 100%;
			border: 1px solid $dark;
			padding: 10px;
			float: left;
			background-color: #fff;
			overflow: hidden;
			img{
				@extend %imgCropped;
				object-fit: contain
			}
		}
		&__name{
			float: left;
			width: calc(100% - 100px);
			padding-left: 20px;
			margin-top: 10px;
			@include mobile {
				margin-top: 0;
			}
			h5{
				text-transform: uppercase;
				font-size: 16px;
				line-height: 1.35em;
				font-weight: bold;
				color: $dark;
			}
			span{
				margin-top: 10px;
				font-size: 16px;
				float: left;
			}
		}
	}
	&__middle{
		@extend %fullBlock;
		margin-top: 20px;
		padding: 10px 0;
		border-top: 1px solid $dark;
		border-bottom: 1px solid $dark;
		line-height: 1.5em;
		ul{
			display: flex;
			width: 100%;
			overflow: hidden;
		}
		li{
			float: left;
			border-right: 1px solid $dark;
			padding-right: 10px;
			margin-right: 10px;
			font-size: 14px;
			color: #44494b;
			text-transform: uppercase;
			white-space: nowrap;
			&:last-child{
				border-right: 0;
				padding-right: 0;
				margin-right: 0;
			}
		}
	}
	&__bottom{
		@extend %fullBlock;
		margin-top: 20px;
		p{
			font-size: 16px;
			line-height: 1.35em;
			color: $dark;
		}
	}
}
.column-2-5{
	float: left;
	width: 20%;
}
.item-holder{
	transition: $transition1;
	margin-bottom: 30px;
	&:hover .img-thumb{
		border: 1px solid $purple;
		border-bottom: none;
    }
	a{
		text-decoration: none;
	}
	.img-thumb{
		float: left;
		width: 100%;
		height: 130px;
		padding: 10px;
		border: 1px solid #ddd;
		border-bottom: none;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-align: center;
	    align-items: center;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -ms-flex-pack: center;
	    justify-content: center;
	    position: relative;
	    transition: $transition1;
	    @include mobile {
	    	height: 80px;
	    	padding: 5px; 
	    }
		img{
			@extend %imgCropped;
			object-fit: contain;
		}
		span{
			float: left;
			position: absolute;
			top: 0;
			left: -1px;
			background: $purple;
			color: #fff;
			padding: 3px 5px 5px;
			font-size: 12px;
			font-weight: bold;
		}
	}
	.item-content{
		float: left;
		width: 100%;
		height: 60px;
		overflow: hidden;
		padding: 5px 10px;
		background: #e6e6e6;
		@include mobile {
			height: 42px;
			padding: 3px 10px;
		}
		h3{
			color: #44494b;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.2em;
		}
	}
	
	.location{
		float: left;
		width: 100%;
		padding: 5px 10px 10px;
		background: #e6e6e6;
		border-top: 1px solid #fff;
		overflow: hidden;
		p{
			white-space: nowrap;
		}
	}
	.date-time{
		float: left;
		width: 100%;
		background: $purple;
		color: #fff;
		padding: 5px 10px 10px;
		font-size: 13px;
		@include mobile {
			padding: 5px 10px 5px;
		}
	}
}
.sectionTitle{
	float: left;
	width: 100%;
    margin: 20px 0;
    font-size: 28px;
    line-height: 1.35em;
    border-bottom: 2px solid $purple;
    padding-bottom: 10px;
    text-transform: uppercase;
}
.single {
	.sectionTitle{
		@include mobile {
	    	margin-top: 0;
	    }
	}
}
.text-align-center{
	text-align: center;
}
.owl-nav{
	float: right;
	margin-top: 20px;
	&.disabled{
		display: block!important;
	}
	span{
		display: none;
	}
	.owl-prev{
		float: left;
		width: 20px;
		height: 20px;
		outline: none;
		margin-right: 20px;
		background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyLjAwOCA1MTIuMDA4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIuMDA4IDUxMi4wMDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGcgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMS4yMjQ2NWUtMTYgLTEuMjI0NjVlLTE2IC0xIDUxMi4wMDggNTEyLjAwOCkiPjxnPgoJPGc+CgkJPHBhdGggZD0iTTM4MS4wNDgsMjQ4LjYzM0wxNDYuMzgxLDMuMjk5Yy0zLjAyMS0zLjE0Ni03LjY0Ni00LjE2Ny0xMS42ODgtMi41MjFjLTQuMDQyLDEuNjE1LTYuNjg4LDUuNTQyLTYuNjg4LDkuODk2djQyLjY2NyAgICBjMCwyLjcyOSwxLjA0Miw1LjM1NCwyLjkxNyw3LjMzM2wxODUuMDYzLDE5NS4zMzNMMTMwLjkyMyw0NTEuMzQxYy0xLjg3NSwxLjk3OS0yLjkxNyw0LjYwNC0yLjkxNyw3LjMzM3Y0Mi42NjcgICAgYzAsNC4zNTQsMi42NDYsOC4yODEsNi42ODgsOS44OTZjMS4yOTIsMC41MjEsMi42NDYsMC43NzEsMy45NzksMC43NzFjMi44NTQsMCw1LjY0Ni0xLjE0Niw3LjcwOC0zLjI5MmwyMzQuNjY3LTI0NS4zMzMgICAgQzM4NC45ODYsMjU5LjI1OCwzODQuOTg2LDI1Mi43NTgsMzgxLjA0OCwyNDguNjMzeiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojNjUyMzYyIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=') !important;
		background-size: 100% !important;
	}
	.owl-next{
		float: left;
		width: 20px;
		height: 20px;
		outline: none;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyLjAwOCA1MTIuMDA4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIuMDA4IDUxMi4wMDg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMzgxLjA0OCwyNDguNjMzTDE0Ni4zODEsMy4yOTljLTMuMDIxLTMuMTQ2LTcuNjQ2LTQuMTY3LTExLjY4OC0yLjUyMWMtNC4wNDIsMS42MTUtNi42ODgsNS41NDItNi42ODgsOS44OTZ2NDIuNjY3ICAgIGMwLDIuNzI5LDEuMDQyLDUuMzU0LDIuOTE3LDcuMzMzbDE4NS4wNjMsMTk1LjMzM0wxMzAuOTIzLDQ1MS4zNDFjLTEuODc1LDEuOTc5LTIuOTE3LDQuNjA0LTIuOTE3LDcuMzMzdjQyLjY2NyAgICBjMCw0LjM1NCwyLjY0Niw4LjI4MSw2LjY4OCw5Ljg5NmMxLjI5MiwwLjUyMSwyLjY0NiwwLjc3MSwzLjk3OSwwLjc3MWMyLjg1NCwwLDUuNjQ2LTEuMTQ2LDcuNzA4LTMuMjkybDIzNC42NjctMjQ1LjMzMyAgICBDMzg0Ljk4NiwyNTkuMjU4LDM4NC45ODYsMjUyLjc1OCwzODEuMDQ4LDI0OC42MzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiM2NTIzNjIiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==') !important;
		background-size: 100% !important;
	}
}
.blog_content {
    @extend %fullBlock;
    p {
        font-size: 18px;
        line-height: 1.55em;
        font-weight: 300;
        margin-top: 20px;
    }
    figcaption {
        font-size: 14px;
        font-weight: 400;
        color: #374351;
        line-height: 1.32em;
    }
    ul {
        list-style: none;
        li {
            font-size: 16px;
            font-weight: 300;
            color: #374351;
            line-height: 1.32em;
            margin-top: 5px;
            display: flex;
            &:before {
                content: " - ";
                padding-right: 10px;
            }
        }
    }
    table {
        margin: 0 auto;
        margin-top: 20px;
        border-left: 1px solid #ebf0f3;
        border-top: 1px solid #ebf0f3;
    }

    th,
    td {
        padding: 10px 15px;
        border-right: 1px solid #ebf0f3;
        border-bottom: 1px solid #ebf0f3;
    }
    blockquote {
        font-size: 24px;
        font-weight: bold;
        color: $purple;
        position: relative;

        p {
            font-size: 24px;
            color: $purple;
            font-weight: 500;
            margin-top: 15px;
            max-width: 80%;

            &:before {
                content: "";
                position: absolute;
                left: -20px;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: $purple;
            }
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 15px;
        color: #374351;
        line-height: 1.25em;
        font-weight: 500;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 14px;
    }
    ol {
        list-style: decimal;
        padding-left: 20px;
        margin-top: 15px;

        li {
            margin-top: 5px;
            font-size: 16px;
            font-weight: 300;
            color: #374351;
        }
    }
    img {
        width: auto;
        text-align: center;
        height: auto;
        margin-top: 15px;
    }
    iframe {
        margin-top: 15px !important;
        text-align: center;

        @include mobile {
            width: 100% !important;
            max-width: 100% !important;
            height: auto;
            min-height: 210px;
        }
        @include tablet {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
    .fb_iframe_widget {
        float: left;
        width: 100%;
        margin-bottom: 35px;

        @include mobile {
            width: 100% !important;
            max-width: 100% !important;
        }
        @include tablet {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
    a {
        color: $purple;
    }
}
.contact_form{
	@include mobile {
		margin-top: 40px;
	}
    form {
    	float: left;
        width: 100%;
    }

    input,
    select,
    textarea {
        margin-bottom: 15px;
        float: left;
        width: 100%;
        padding: 15px;
        border: 1px solid rgba($purple, 0.7);
        outline: 0;
        border-radius: 3px;
        background-color: rgba($purple, 0.01);
        transition: $transition1;
        resize: none;
        &:focus{
            border-color: $purple;
        }
        @include placeholder {
            font-size: 16px;
            color: rgba($purple, 0.5);
        }
    }
    label{
        span{
            float: left;
            width: 100%;
            margin-top: 5px;
        }
    }
    textarea {
        height: 200px;
    }

    input[type="submit"] {
        border-color: transparent;
        float: right;
        cursor: pointer;
        background-color: $purple;
        width: 200px;
        text-transform: uppercase;
        color: #fff;
        letter-spacing: 0.5px;
        transition: $transition1;
        &:hover{
            background: transparent;
            color: $purple;
            border-color: $purple;
        }
    }
}
/* Right Side */
.rightSide {
	width: 300px;
	float: left;
	@include mobile {
		margin-top: 20px;
	}
}
.a-single{
	text-align: center;
}
.ad-sidebar {
	width: 300px;
	background-color: #f8f8f8;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.main_title_sidebar{
	font-size: 16px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid $purple;
	background-color: $purple;
	text-align: center;
	padding: 5px 0;
}
.sidebar_list{
	@extend %fullBlock;
	margin-bottom: 20px;
	li{
		@extend %fullBlock;
		border-bottom: 1px solid $dark;
		a{
			@extend %fullBlock;
			text-decoration: none;
			font-size: 16px;
			color: $dark;
			padding: 8px 0;
			transition: $transition1;
			&:hover{
				background-color: #c3c3c3;
			}
		}
	}
}
.mb20{
	margin-bottom: 20px;
}
.mb30{
	margin-bottom: 30px;
}
.blog_list{
	@extend %fullBlock;
	li{
		margin-top: 20px;
		border-bottom: 1px solid $dark;
		padding-bottom: 10px;
	}
	a{
		@extend %fullBlock;
		text-decoration: none;
		color: $dark;
		transition: $transition1;
		&:hover{
			color: $purple;
		}
	}
	h3{
		font-size: 18px;
		line-height: 1.35em;
		font-weight: bold;
	}
	p{
		font-size: 16px;
		line-height: 1.35em;
		font-weight: 300;
		margin-top: 5px;
	}
}
.company{
	float: left;
	width: 100%;
	display: flex;
	background: #f4f4f4;
    padding-top: 30px;
	&__logo{
		float: left;
		width: 200px;
		height: 100px;
	}
}
.information{
	float: left;
	width: 100%;
    padding-top: 30px;
	border-top: 1px solid #fff;
    background: #f4f4f4;
    margin-bottom: 20px;
}
.single__item{
	float: left;
    width: 25%;
    padding: 10px 15px;
    margin-bottom: 20px;
    background: #f4f4f4;
    h6{
    	font-weight: bold;
    	margin-bottom: 5px;
    }
    h4{
    	line-height: 1.2em;
    }

}
.page_banner{
	float: left;
	width: 100%;
	height: 300px;
	margin-bottom: 20px;
	position: relative;
	z-index: 1;
	background: rgb(2,0,36);
	background: -webkit-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
	background: -o-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
	background: linear-gradient(to right, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
	.banner-content{
		@extend %centered;
		text-align: center;
		h4{
			font-size: 24px;
			color: #fff;
			a{
				margin-top: 10px;
				display: block;
			}
		}
	}
}
.boxes_area{
	float: left;
	width: 100%;
	margin-top: 20px;
	.box_inner{
		h4{
			padding: 10px;
			color: #fff;
			font-size: 18px;
			text-align: center;
			font-weight: 500;
			background: rgb(2,0,36);
			background: -webkit-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
			background: -o-linear-gradient(left, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
			background: linear-gradient(to right, rgba(2,0,36,1) 0%, rgba(101,35,98,1) 35%);
		}
		&_content{
			float: left;
			width: 100%;
			padding: 20px 10px 10px;
			border: 1px solid $purple;
			ul{
				li{
					margin-bottom: 10px;
					font-size: 14px;
					position: relative;
					padding-left: 10px;
					&:before{
						content: "";
						display: inline-block;
						width: 4px;
						height: 4px;
						background: $purple;
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 6px;
					}
				}
			}
		}
	}
}
.gfield{
	float: left;
	width: 50%;
	clear: none !important;
	input{
		width: 100% !important;
	}
	.gfield_select{
		padding: 5px !important;
	    width: 100% !important;
	    margin-bottom: 1px !important;
	}
}
.gfield .name_first label{
	display: none !important;
}
.ginput_container_fileupload {
	input{
		float: left;
		padding: 6px;
	    width: auto !important;
	}
}
#gform_submit_button_1{
	border: none;
	padding: 6px 20px 10px;
	color: #fff;
	background: $purple;
}
.btn_submit{
	float: left;
	background-color: $purple;
	padding: 15px 60px;
	text-decoration: none;
	border: 1px solid $purple;
	border-radius: 5px;
	font-size: 18px;
	text-transform: uppercase;
	color: #fff!important;
	margin-top: 20px;
	margin-bottom: 20px;
	transition: $transition1;
	&:hover{
		background-color: transparent;
		color: $purple!important;
	}
}
.list_social_share {
    float: right;
    list-style: none;
    margin-top: 13px;
    @include mobile {
    	margin-bottom: 15px;
    	margin-top: 0; 
    	float: left;
    }
    li {
    	float: left;
        margin-top: 10px;
        margin-left: 15px;
        &:before {
        	display: none;
        }
        &:first-child {
            margin-left: 0;
        }
        a {
            float: left;
            width: 40px;
            height: 40px;
            border: 1px solid #ececec;
            i {
                background-size: 18px;
			    width: 100%;
			    height: 100%;
			    float: left;
			    background-repeat: no-repeat;
			    background-position: center;
            }
            &:hover {
                border-color: $purple;
            }
        }
    }
}