@include mobile {
	.leftSide{
		width: 100%;
		padding-right: 0;
	}
	.gfield{
		width: 100% !important;
		input{
			outline: none !important;    
		    -webkit-appearance: none !important;
		    -moz-appearance: none !important;
		}
	}
	.ad-sidebar, .rightSide{
		width: 100%;
	}
	.column-mob-6{
		width: 50% !important;
	}
	.company__logo{
		width: 100%;
		height: auto; 
		padding: 16px;
	}
	.company{
		display: block;
	}
	.single__item{
		width: 50%;
		min-height: 80px;
	}
	.nav_wrapper{
		ul{
			height: 100%;
			align-items: center;
			padding-bottom: 0;
		    padding-right: 0;
		    justify-content: center;
		    li{
		    	margin-bottom: 10px;
		    }
		}
	}
	.menu-primary-menu-container{
		height: 100%;
	}
	#search-icon{
		display: none;
	}
	.form_holder .form_holder_inner{
		max-width: 100%;
		min-height: 1px;
		padding: 10px;
		width: calc(100% - 20px) !important;
	}
	.form_holder .form_holder_inner .input-search{
		width: 70%;
	}
	.form_holder .form_holder_inner .input-submit{
		width: 24%;
	}
	.search-icon{
		margin-right: 20px;
	}
}
@include tablet {
	.leftSide{
		width: 100%;
		padding-right: 0;
	}
	.rightSide {
		margin-top: 20px;
	}
	.gfield{
		width: 100% !important;
		input{
			outline: none !important;    
		    -webkit-appearance: none !important;
		    -moz-appearance: none !important;
		}
	}
	.ad-sidebar, .rightSide{
		width: 100%;
	}
	.column-mob-6{
		width: 50% !important;
	}
	.company{
		display: block;
	}
	.single__item{
		width: 25%;
		min-height: 80px;
	}
	.nav_wrapper{
		ul{
			height: 100%;
			align-items: center;
			padding-bottom: 0;
		    padding-right: 0;
		    justify-content: center;
		    li{
		    	margin-bottom: 10px;
		    }
		}
	}
	.menu-primary-menu-container{
		height: 100%;
	}
	#search-icon{
		display: none;
	}
	.form_holder .form_holder_inner{
		max-width: 100%;
		min-height: 1px;
		padding: 10px;
		width: calc(100% - 20px) !important;
	}
	.form_holder .form_holder_inner .input-search{
		width: 70%;
	}
	.form_holder .form_holder_inner .input-submit{
		width: 24%;
	}
	.search-icon{
		margin-right: 20px;
	}
}
