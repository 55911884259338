.headerWrap{
	padding: 10px 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.13);
    background-color: #fff;
}
.logo{
	float: left;
	margin-top: 20px;
	@include mobile {
		width: 200px
	}
}
.top_header{
	float: left;
	width: 100%;
	@include mobile {
	    display: flex;
	    flex-direction: column-reverse;
	}
	@include tablet {
	    display: flex;
	    flex-direction: column-reverse;
	}
}
.nav_wrapper{
	float: left;
	width: 100%;
	margin-top: 10px;
    padding-top: 8px;
    position: relative;
	border-top: 1px solid $purple;
	transition: $transition2;
	@include mobile {
		height: 0;
		opacity: 0;
		overflow: hidden;
		position: absolute;
	    top: 168px;
		background: #fff;
		z-index: 99;
		width: 100%;
		left: 0;
		margin-left: 0;
		&.is--active{
			overflow: visible;		
		    height: calc(100vh - 130px);
		    opacity: 1;
		}
	}
	@include tablet {
		height: 0;
		opacity: 0;
		overflow: hidden;
		position: absolute;
	    top: 167px;
		background: #fff;
		z-index: 99;
		width: 100%;
		left: 0;
		margin-left: 0;
		&.is--active{
			overflow: visible;		
		    height: calc(100vh - 130px);
		    opacity: 1;
		}
	}
	@include tablet-big {
		height: 0;
		overflow: hidden;
		position: absolute;
		top: 85px;
		background: #fff;
		z-index: 99;
		width: 100%;
		left: 0;
		margin-left: 0;
		-webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
		&.is--active{
			height: auto;
			overflow: visible;		
		}
	}
	ul{
		@include mobile {
			display: flex;
		    width: 100%;
		    flex-direction: column;
		    align-items: flex-end;
		    padding-bottom: 20px;
		    padding-right: 10px;
		}
		@include tablet {
			display: flex;
		    width: 100%;
		    flex-direction: column;
		    align-items: flex-end;
		    padding-bottom: 20px;
		    padding-right: 10px;
		}
		@include tablet-big {
			display: flex;
		    width: 100%;
		    flex-direction: column;
		    align-items: flex-end;
		    padding-bottom: 20px;
		    padding-right: 10px;
		}
	}
	li{
		float: left;
		margin-top: 5px;
		margin-bottom: 5px;
		.sub-menu{
			position: absolute;
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
		}
	}
	.current-menu-item {
		a{
			color: $purple;
		}
	}
	a{
		float: left;
		padding: 0 10px;
		font-size: 15px;
		font-weight: 600;
		text-decoration: none;
		color: #374351;
		text-transform: uppercase;
		transition: $transition1;
		&:hover {
			color: $purple;
		}
	}
	.openContact{
		margin-top: 8px;
		margin-left: 15px;
		user-select: none;
		cursor: pointer;
		@include mobile {
			margin-top: 15px;
		}
		@include tablet {
			margin-top: 15px;
		}
		@include tablet-big {
			margin-top: 15px;
		}
		a{
			font-weight: normal;
			text-transform: uppercase;
			border: 1px solid #fff;
			border-radius: 3px;
		    padding: 8px 13px;
			background-color: #fff;
			color: #2f1110;
			transition: $transition1;
			&:hover {
				background-color: #2f1110;
				color: #fff;
			}
		}
	}
}
.hamburger{
	float: right;
	cursor:pointer;
	display: none;
	@include mobile {
		display: block;
	}
	@include tablet {
		display: block;
	}
	@include tablet-big {
		display: block;
	}
	span{
		float: left;
		clear: both;
		@include rem('width',35px);
		@include rem('margin-bottom',7px);
		transition:$transition1;
		&:last-child{
			margin-bottom: 0
		}
		&:after{
			content: "";
			@include rem('width',35px);
			@include rem('height',3px);
			border-radius: 3px;
			transition:$transition2;
			float: left;
			background-color: $purple;
		}
	}
}
.is--active.hamburger{
	span{
		&:nth-child(1){
			transform: rotate(-45deg) translateX(-10px) translateY(5px);
		}
		&:nth-child(2){
			opacity: 0;
		}
		&:nth-child(3){
			transform: rotate(45deg) translateX(-10px) translateY(-5px);
		}
	}
}
#header{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
}
.mobile_menu_trigger{
    float: right;
    margin-top: 16px;
    @include mobile {
	    margin-top: -34px;
    }
    @include tablet {
	    margin-top: -42px;
    }
    a{
    	font-size: 16px;
	    text-transform: uppercase;
	    text-decoration: none;
	    color: $purple;
    }
}
.ad-banner-header{
	transition: $transition1;
}
.is--sticky--header{
	.logo{
		width: 190px;
    	margin-top: 0px;
	}
	.ad-banner-header{
	    display: none;
	}
	.mobile_menu_trigger{
	    margin-top: 12px;
	}
	.nav_wrapper{
		float: right;
		width: auto;
		border: 0;
		margin-top: 2px;
		padding-right: 40px;
		@include mobile {
			width: 100%;
		    padding-right: 0;
		    margin-top: 10px;
	        border-top: 1px solid #652362;
			top: 44px;
			&.is--active{
				height: calc(100vh - 64px);
			}
		}
		@include tablet {
			width: 100%;
		    padding-right: 0;
		    margin-top: 10px;
	        border-top: 1px solid #652362;
			top: 44px;
			&.is--active{
				height: calc(100vh - 64px);
			}
		}
	}
	.top_header{
		width: auto;
	}
}
.search-icon{
	float: left;
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: relative;
	z-index: 9;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTExLjk5OSA1MTEuOTk5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTEuOTk5IDUxMS45OTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNTA4Ljg3NCw0NzguNzA4TDM2MC4xNDIsMzI5Ljk3NmMyOC4yMS0zNC44MjcsNDUuMTkxLTc5LjEwMyw0NS4xOTEtMTI3LjMwOWMwLTExMS43NS05MC45MTctMjAyLjY2Ny0yMDIuNjY3LTIwMi42NjcgICAgUzAsOTAuOTE3LDAsMjAyLjY2N3M5MC45MTcsMjAyLjY2NywyMDIuNjY3LDIwMi42NjdjNDguMjA2LDAsOTIuNDgyLTE2Ljk4MiwxMjcuMzA5LTQ1LjE5MWwxNDguNzMyLDE0OC43MzIgICAgYzQuMTY3LDQuMTY1LDEwLjkxOSw0LjE2NSwxNS4wODYsMGwxNS4wODEtMTUuMDgyQzUxMy4wNCw0ODkuNjI3LDUxMy4wNCw0ODIuODczLDUwOC44NzQsNDc4LjcwOHogTTIwMi42NjcsMzYyLjY2NyAgICBjLTg4LjIyOSwwLTE2MC03MS43NzEtMTYwLTE2MHM3MS43NzEtMTYwLDE2MC0xNjBzMTYwLDcxLjc3MSwxNjAsMTYwUzI5MC44OTYsMzYyLjY2NywyMDIuNjY3LDM2Mi42Njd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMzNzQzNTEiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==');
	background-size: 100% !important;
}
.search-toggle{
	.search-icon{
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQxMy4zNDggNDEzLjM0OCIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCA0MTMuMzQ4IDQxMy4zNDgiIHdpZHRoPSI1MTIiIGNsYXNzPSIiPjxnPjxwYXRoIGQ9Im00MTMuMzQ4IDI0LjM1NC0yNC4zNTQtMjQuMzU0LTE4Mi4zMiAxODIuMzItMTgyLjMyLTE4Mi4zMi0yNC4zNTQgMjQuMzU0IDE4Mi4zMiAxODIuMzItMTgyLjMyIDE4Mi4zMiAyNC4zNTQgMjQuMzU0IDE4Mi4zMi0xODIuMzIgMTgyLjMyIDE4Mi4zMiAyNC4zNTQtMjQuMzU0LTE4Mi4zMi0xODIuMzJ6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMwMDAwMDAiPjwvcGF0aD48L2c+IDwvc3ZnPg==')
	}
}
.search_area{
	float: right;
	position: absolute;
	top: 10px;
	right: 0;	
}
.form_holder{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.9);
	&.is--active{
		display: block;
	}
	.form_holder_inner{
		@extend %centered;
		display: block;
	    max-width: 700px;
	    min-height: 200px;
	    width: 100%;
	    padding: 40px;
	    border: 1px solid grey;
	    background: #fff;
	    .input-search{
	    	float: left;
	    	width: 80%;
	    }
	    input{
    		width: 100%;
    		padding: 10px;
    		outline: none;
    		border: none;
    		box-shadow: none;
    		border: 1px solid grey;
    	}
	    .input-submit{
	    	width: 16%;
	    	float: right;
	    	input{
	    		width: 100%;
	    		cursor: pointer;
	    		background: #fff;
	    	}
	    }
	    .input-holder{
	    	float: left;
	    	width: 100%;
	    	margin-top: 20px;
	    	select{
	    		float: left;
	    		width: 48%;
	    		padding: 10px;
	    		&:last-child{
	    			float: right;
	    		}
	    	}
	    }
	}
}